
import config from 'data/config/config';
import configureStatusBar from 'data/config/cordovaStatusBarConfig';

import getInAppBrowserOptions from 'data/config/inAppBrowserOptions';
import { isAndroid, isIOS, getAndroidVersion } from 'src/core/util/browser';
import * as Lang from 'src/core/Lang';
import { get as getProfile } from 'src/core/Profile';
import { parseUrl, queuedNavigation } from 'src/core/navigation/Router';
import { notificationHandler } from 'src/pages/inbox/InboxService';
import { addKeyBoardListeners } from 'src/core/keyboard/KeyboardHelper';
import { getBindedActions } from 'src/store/bindedActions';

const LOG_PREF = '[CordovaHelper] ';


export function init() {

    if (global.isCordovaContext !== true) {
        return;
    }

    addKeyBoardListeners();

    // Cookie issue on iOS/wkWebview
    // See https://github.com/ionic-team/cordova-plugin-ionic-webview/issues/22
    if (isIOS()) {
        if (!global.wkWebView) {
            console.error(LOG_PREF+'\'wkWebView\' global is missing, it should be provided by plugin cordova-plugin-wkwebview-inject-cookie');
        } else {
            let urls = [];
            function addUrl(url) {
                if (urls.indexOf(url) === -1) {
                    urls.push(url);
                }
            }
            if (config.LOGIN && config.LOGIN.FEATURE_ENABLED) {
                addUrl(config.LOGIN.WS_URL);
            }
            if (config.USER_DATA && config.USER_DATA.FEATURE_ENABLED) {
                addUrl(config.USER_DATA.WS_URL);
            }
            if (config.PARTICIPANTS && config.PARTICIPANTS.FEATURE_ENABLED) {
                addUrl(config.PARTICIPANTS.WS_URL);
            }

            urls.forEach(url => {
                console.log(LOG_PREF+'Calling cordova plugin to handle cookie for url: '+url);
                global.wkWebView.injectCookie(url);
            });
        }
    }


    // ANDROID BACK BUTTON BEHAVIOUR
    document.addEventListener('backbutton', e => {
        console.info(LOG_PREF+'Back button triggered');
        getBindedActions().navigateBack();
    }, false);


    // IN APP BROWSER
    if (cordova.InAppBrowser) {
        const nativeOpen = window.open
        window.open = function(url, target) {
            let options = getInAppBrowserOptions(
                isAndroid() ? 'android' : 'ios',
                Lang.get(),
            );

            // target option to bypass inappbrowser
            if (target === '_native') {
              nativeOpen(url, '_blank');
            } else {
              cordova.InAppBrowser.open(url, target, options);
            }
        };
    }

    /**
     * Handle when app is opened via an url
     * e.g http://ph-dev.mobile-spot.com/?/service&originalId=5c442084-9ee7-e811-80d9-005056ae0696&lang=fr&profile=default
     */
    if (window.mobilespotApp && typeof window.mobilespotApp.getIntent === 'function') {

        function getIntentAndNavigate() {
            window.mobilespotApp.getIntent(
                function getIntentSuccessCb(intentUri) {
                    if (intentUri) {
                        console.log(LOG_PREF+'App started with intent url: ' + intentUri);
                        queuedNavigation.set(() => parseUrl(intentUri));
                    }
                },
                function getIntentFailureCb(...args) {
                    console.error(LOG_PREF+'Failed to execute window.mobilespotApp.getIntent', args);
                }
            );
        }

        document.addEventListener('onIntent', function(event) {
            console.info(LOG_PREF+'onIntent event received ;)', event);
            getIntentAndNavigate();
        });

        // Check if app has been started with query parameters
        getIntentAndNavigate();
    }


    if (hasPendingNotificationsAPI()) {
        document.addEventListener('onNotification', function() {
            console.log(LOG_PREF+'Received onNotification event');
            getPendingNotifications();
        });

        // Get pending notifications on app startup
        getPendingNotifications();
    }
};

export function hasPendingNotificationsAPI() {
    return window.notificationManager && typeof window.notificationManager.getPendingNotifications === 'function';
}

export function getPendingNotifications() {
    if (!getProfile()) {
        // Profile needs to be set first because the user can navigate from the notification dialog
        // @see cordovaReducerAndMiddleware.js
        return;
    }
    if (hasPendingNotificationsAPI()) {
        window.notificationManager.getPendingNotifications(
            function getPendingNotificationsSuccess(sNotifs) {
                console.log(LOG_PREF+'getPendingNotifications', sNotifs);
                var notifs;
                if (sNotifs) {
                    var parsed = JSON.parse(sNotifs);
                    if (parsed && parsed.notifications) {
                        notifs = parsed.notifications;
                    }
                }

                if (Array.isArray(notifs) && notifs.length) {
                    // NB: array contains notifications in reverse chronological order
                    notifs.reverse().forEach(notificationHandler);
                }
            },
            function getPendingNotificationsError() {
                console.log(LOG_PREF+'getPendingNotifications error', arguments);
            },
        );
    }
}


export function customizeStatusBar(pageKey) {
    if (!global.isCordovaContext) {
        return;
    }
    if (!global.StatusBar) {
        // console.log(LOG_PREF+'No status bar plugin installed');
    }
    else if (typeof configureStatusBar !== 'function') {
        // console.log(LOG_PREF+'No status bar configuration found');
    }
    else {
        let androidVersion = getAndroidVersion();
        if (androidVersion && androidVersion < 6) {
            // Android 5 does not really support status bar styling
            return;
        }
        configureStatusBar(pageKey);
    }
}
