import React from 'react';
import PropTypes from 'prop-types';

import IphonexFlexMargin from 'src/components/iphonex-flex-margin/IphonexFlexMargin';
import List             from 'src/components/list/List';
import TypeBar          from 'src/components/type-bar/TypeBar';

import './ContextualSearch.scss'

class ContextualSearch extends React.Component {

    state = {}

    getDetail = () => (
        Object.keys(this.props.items).map(dataType => {
            let items = this.props.items[dataType];
            if (items.length === 0) return null

            const listProps = {
                key              : dataType,
                actions          : this.props.actions,
                labels           : this.props.labels,
                dataType         : dataType,
                favorites        : this.props.favorites,
                displayFavorites : this.props.favIconDisabled !== true,
                items            : items,
                onItemClick      : this.props.onItemClick,
                userData         : this.props.userData,
                pageKey          : this.props.pageKey,
            };
            return (
                <TypeBar key={dataType}
                         label={this.props.labels.data[dataType].plural}>
                    <List {...listProps} />
                </TypeBar>
            );
        })
    )

    isListEmpty = () => {
        const hasItems = this.props.items !== null && typeof this.props.items === 'object';
        if (!hasItems) return true

        const noData = hasItems && (Object.keys(this.props.items).length === 0
            || Object.keys(this.props.items).every(key => !this.props.items[key]
                || (Array.isArray(this.props.items[key]) && this.props.items[key].length === 0)))

        return noData;
    }

    render() {
        return (
            <div className={"contextual-search-results content-below-apptoolbar "+(this.props.className || '')}>

                <div className="list-search-content content-font">
                    { this.isListEmpty()
                        ? <div className="list-empty">{this.props.labels.common.noData}</div>
                        : this.getDetail()
                    }
                </div>

                { typeof this.props.keyboardHeight === 'number' && this.props.keyboardHeight > 0 &&
                    <div className="dummy-keyboard-placeholder" style={{ flexBasis: this.props.keyboardHeight }}></div>
                }

                <IphonexFlexMargin />
            </div>
        );
    }
}

ContextualSearch.props = {
    className: PropTypes.string,
    items: PropTypes.object,
    actions: PropTypes.object.isRequired,
    labels: PropTypes.object.isRequired,
    keyboardHeight: PropTypes.number,
    userData: PropTypes.object,
    pageKey: PropTypes.string,
};

export default ContextualSearch;
