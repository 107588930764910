
import { debounce } from 'lodash-custom';

import config from 'data/config/config';
import { DATA_TYPE_PARTICIPANTS } from 'data/config/dataConfig';

import * as ParticipantsWsTaiga from 'src/core/webservices/taiga/ParticipantsWS';
import { parseDate } from 'src/core/webservices/taiga/helpers';
import { TAIGA } from 'src/core/webservices/WsProviders';
import * as UserDataService from 'src/core/user-data/UserDataService';
import { appendOrUpdateSortedAndTransformedData } from 'src/core/data-and-assets/Db';
import { getAll } from 'src/core/query/Query';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';
import { get as getLabels } from 'src/core/Lang';
import { getBindedActions } from 'src/store/bindedActions';
import { WS_ERRORS } from 'src/core/webservices/WS_ERRORS';


const LOG_PREF = '[ParticipantsService] ';


export function isSameUser(participant) {
    if (participant) {
        let userData = UserDataService.getUserData();
        if (userData) {
            return userData.id === participant.id;
        }
    }
    return false;
}

export function getContactDate(participant) {
    if (participant) {
        let userData = UserDataService.getUserData();
        if (userData) {
            let contactData = (userData.contactedParticipants || []).find(data => data.contactId === participant.id);
            if (contactData) {
                // string such as "2019-10-25 09:00:39"
                return parseDate( contactData.contactDate );
            }
        }
    }
    return null;
}


let lastFetchDate;

function hasDataExpired() {
    if (!lastFetchDate) {
        return true;
    }
    let delaySinceLastFetch = lastFetchDate && new Date().getTime()-lastFetchDate;
    return delaySinceLastFetch > config.PARTICIPANTS.DATA_EXPIRE_DELAY;
}


/**
 * Fetch participants from web service
 *
 * debounced because it can be called by multiple sources at boot time, e.g:
 *  - after background login success (see participantsMiddleware)
 *  - user landing on participants list
 *
 * @param  {function} cb
 * @param  {boolean}  force getting data from web service
 */
export const getParticipants = debounce((cb, skipCache) => {
    let hasCallback = typeof cb === 'function';

    if (lastFetchDate && hasCallback) {
        cb(null, getAll(DATA_TYPE_PARTICIPANTS) || []);
    }
    if (!hasDataExpired() && !skipCache) {
        return;
    }

    function next(error, data) {
        if (data) {
            lastFetchDate = new Date().getTime();
            appendOrUpdateSortedAndTransformedData(data, DATA_TYPE_PARTICIPANTS);
            if (!hasCallback) {
                getBindedActions().dataAssetsUpdated([ DATA_TYPE_PARTICIPANTS ]);
            }
        }
        if (error === WS_ERRORS.NETWORK) {
            getBindedActions().showNotification({
                message: getLabels().common.noNetworkTitle,
                level: NotificationLevels.WARNING,
            });
        }
        if (hasCallback) {
            cb(error, data);
        }
    }

    switch (config.PARTICIPANTS.PROVIDER) {

        case TAIGA:
            ParticipantsWsTaiga.getParticipants(next);
            break;

        default:
            console.error(LOG_PREF+'Unexpected web service provider: '+config.PARTICIPANTS.PROVIDER);
    }
}, 400);

if (config.ENV === 'dev') {
    global.getParticipants = getParticipants;
}


/**
 * Trigger participant contact by calling web service
 * @param  {Function} cb
 */
export function contactParticipant(participantId, cb) {

    function next(error) {
        getBindedActions().contactRequestPerformed({
            id: participantId,
            dataType: DATA_TYPE_PARTICIPANTS,
            ws: config.PARTICIPANTS.PROVIDER,
            error: error,
        });

        if (typeof cb === 'function') {
            cb(error);
        }
        UserDataService.refreshUserDataFromAPI();
    }

    switch (config.PARTICIPANTS.PROVIDER) {

        case TAIGA:
            ParticipantsWsTaiga.contactParticipant(participantId, next);
            break;

        default:
            console.error(LOG_PREF+'Unexpected web service provider: '+config.PARTICIPANTS.PROVIDER);
    }
}
if (config.ENV === 'dev') {
    global.contactParticipant = contactParticipant;
}
