
import config from 'data/config/config';
import {
    get as getSearchConfig,
    DATATYPES_WITH_PLACES,
} from 'data/config/searchConfig';

import STATUS from 'src/store/fetchStatuses';

import { get as getProfile } from 'src/core/Profile';
import unicodeNormalizer from 'src/core/util/unicodeNormalizer';
import * as Db from 'src/core/data-and-assets/Db';
import { completeData } from 'src/core/query/Query';


const LOG_PREF = '[Search] ';


let regexpCache = {};

function getRegExp(searchedString) {
    if (!regexpCache[searchedString]) {
        regexpCache[searchedString] = new RegExp('\\b('+searchedString+'\\w*)\\b');
    }
    return regexpCache[searchedString];
}

let fullWordRegexpCache = {};

function getFullWordRegExp(searchedString) {
    if (!fullWordRegexpCache[searchedString]) {
        fullWordRegexpCache[searchedString] = new RegExp('\\b('+searchedString+')\\b');
    }
    return fullWordRegexpCache[searchedString];
}


export const SEARCH_TYPES = {
    INDEX_OF: 'INDEX_OF',
    STARTS_WITH: 'STARTS_WITH',
    WORD_STARTS_WITH: 'WORD_STARTS_WITH',
};

const SEARCH_FUNCTIONS = {
    [SEARCH_TYPES.INDEX_OF]: function(fields, searchedString) {
        if (fields.text) {
            const titleMatch = fields.text.indexOf(searchedString) !== -1;
            if (titleMatch) {
                return true
            }
            if (fields.keywords) {
                return fields.keywords.findIndex(keyword => getFullWordRegExp(searchedString).test(keyword)) !== -1
            }
            return false
        }
        if (typeof fields === 'string') {
            return fields.indexOf(searchedString) !== -1;
        }
        return false;
    },
    [SEARCH_TYPES.STARTS_WITH]: function(fields, searchedString) {
        if (fields.text) {
            return fields.text.startsWith(searchedString);
        }
        if (typeof fields === 'string') {
            return fields.startsWith(searchedString);
        }
        return false;
    },
    [SEARCH_TYPES.WORD_STARTS_WITH]: function(fields, searchedString) {
        if (fields.text) {
            return getRegExp(searchedString).test(fields.text);
        }
        if (typeof fields === 'string') {
            return getRegExp(searchedString).test(fields)
        }
        return false;
    },
};

const DEFAULT_SEARCH_FUNCTION = SEARCH_TYPES.INDEX_OF;

const normalizeFields = fields => {
    // fields = string
    // or fields = { text: string, keywords: [string, string] }

    if (typeof fields === 'string') {
        return unicodeNormalizer(fields);
    }

    const normalizedFields = {};

    if (fields.text) {
        normalizedFields.text = unicodeNormalizer(fields.text);
    }

    if (fields.keywords && fields.keywords.length > 0) {
        normalizedFields.keywords = fields.keywords.map(keyword => unicodeNormalizer(keyword));
    }

    if (normalizedFields.text || normalizedFields.keywords) {
        return normalizedFields;
    }

    return '';
}



function _search(tables, value, searchType) {

    let searchResults = {},
        totalCount = 0;

    if (typeof value !== 'string' && value !== null && typeof value !== 'undefined') {
        console.error(LOG_PREF+'Wrong argument type supplied to \'search\': '+typeof value, value);
    }
    else {
        const normalizedInput = unicodeNormalizer(value);

        let searchConfig = getSearchConfig(getProfile());

        let matcher;
        if (searchType) {
            matcher = SEARCH_FUNCTIONS[searchType];
        }
        if (typeof matcher !== 'function') {
            if (searchType) {
                console.error(LOG_PREF+'Invalid search type provided: '+ searchType+'  Using default: '+DEFAULT_SEARCH_FUNCTION);
            }
            matcher = SEARCH_FUNCTIONS[DEFAULT_SEARCH_FUNCTION];
        }

        // Search through all configured data types
        tables.forEach(dataType => {
            try {
                let data = Db.getSortedAndTransformedData()[dataType],
                    getSearchedField = searchConfig[dataType];

                // data can be missing, or not fetched for a web-service yet
                if (Array.isArray(data)) {

                    searchResults[dataType] = data.filter(item => (
                        matcher(normalizeFields(getSearchedField(item)), normalizedInput)
                    ));

                    // Sort results
                    searchResults[dataType] = Db.sortItems(searchResults[dataType], dataType);

                    totalCount += searchResults[dataType].length;
                }
            } catch (e) {
                console.error(`Failed to search through ${dataType} index`, e);
            }
        });
    }

    return {
        results: searchResults,
        count: totalCount,
    };
}




/**
 * Search through all data
 *
 * @param  {string} value
 * @param  {array}  datatypes - search only through these data types (optional)
 * @param  {string} searchType - match algorithm (optional)
 * @return {object}
 */
export function search(value, datatypes, searchType) {
    let searchConfig = getSearchConfig(getProfile());

    let data = _search(datatypes || Object.keys(searchConfig), value, searchType);
    return {
        status    : STATUS.FETCHED,
        data      : data.results,
        totalCount: data.count,
    };
};

if (config.ENV === 'dev') {
    global.search = search;
}



/**
 * Search through data types that have at least one place
 * NB: places data is fetched too
 *
 * @param  {string} value
 * @param  {array}  datatypes - search only through these data types (optional)
 * @param  {string} searchType - match algorithm (optional)
 * @return {object}
 */
export function searchPlaces(value, datatypes, searchType) {
    let results = _search(datatypes || DATATYPES_WITH_PLACES, value, searchType).results,
        count = 0;

    Object.keys(results).forEach(dataType => {

        // Keep only items with places
        let filtered = results[dataType].filter(item => item.places && item.places.length > 0);

        // Fetch places
        filtered = filtered.map(item => completeData(item, dataType, [ 'places' ]));

        filtered.forEach(item => {
            // Synoptic hack: remove synoptic places only if there is at least one 'real' place
            if (item.references.places.length > 1) {
                item.references.places = item.references.places.filter(place => place.tag.startsWith('Syno') === false);
            }
        });

        results[dataType] = filtered;
        count += filtered.length;
    });

    return {
        status    : STATUS.FETCHED,
        data      : results,
        totalCount: count,
    };
};

if (config.ENV === 'dev') {
    global.searchPlaces = searchPlaces;
}
