
import React from 'react';
import PropTypes from 'prop-types';

import isOnline from 'src/core/util/isOnline';
import showConfirmModal from 'src/core/util/showConfirmModal';
import { getBindedActions } from 'src/store/bindedActions';
import { contactParticipant } from 'src/core/participants/ParticipantsService';
import { WS_ERRORS } from 'src/core/webservices/WS_ERRORS';

import './ContactButton.scss';

const LOG_PREF = '[ContactButton] ';



export default class ContactButton extends React.Component {

    static propTypes = {
        participantId: PropTypes.string,
    }

    state = {
        disabled: false,
    }

    contact = e => {
        e.stopPropagation();
        let btnEl = e.target;
        if (btnEl.attributes.disabled) {
            return;
        }

        let participantId = btnEl.dataset.participantId;
        if (!participantId) {
            console.error(LOG_PREF+'Missing participant id in dataset');
            return;
        }

        let self = this;

        isOnline(hasNetwork => {
            let labels = this.props.labels;

            if (!hasNetwork) {
                getBindedActions().showNotification({
                    message: labels.common.noNetworkTitle
                });
            } else {
                showConfirmModal({
                    title: labels.common.contact,
                    text : labels.common.contactConfirmMessage,
                    yesBtnLabel: labels.common.ok,
                    noBtnLabel : labels.common.cancel,
                    yesCb: function() {
                        self.setState({ disabled: true });

                        contactParticipant(participantId, function(error) {
                            if (error) {
                                let notif = { level: 'error' };
                                if (error === WS_ERRORS.NETWORK) {
                                    notif.title = labels.common.noNetworkTitle;
                                    notif.message = labels.common.noNetwork;
                                } else {
                                    notif.title = labels.common.contactErrorTitle;
                                    notif.message = labels.common.contactError;
                                }
                                getBindedActions().showNotification(notif);
                                // Re-enable button
                                self.setState({ disabled: false });
                            }
                            // in case of success, keep button disabled. data is being refreshed
                        });
                    },
                    noCb: () => {},
                });
            }
        });
    }

    render() {
        if (!this.props.participantId) {
            console.log(LOG_PREF+'Missing participant id property');
            return null;
        }

        return (
            <div
                data-participant-id={this.props.participantId}
                className="contact-btn"
                disabled={this.props.alreadyContacted ? true : this.state.disabled}
                onClick={this.props.alreadyContacted ? null : this.contact}>
                <div className="contact-btn-icon fa fa-envelope"></div>
                {this.props.label && <span className="contact-btn-text">{this.props.label}</span>}
            </div>
        );
    }
}
