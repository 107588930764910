// Libs
import React, { Component }   from 'react';
import PropTypes              from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import { throttle }           from 'lodash-custom';

// Conf
import { DATA_TYPE_EVENTS, CATEGORIES_DATA_TYPE, CATEGORIES_MAPPING } from 'data/config/dataConfig';
import { LIST_GROUPS_PAGE_KEY, LIST_PAGE_KEY } from 'src/pages/pagesKeys';

// App modules
import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import Menu       from 'src/components/menu/Menu';
import ContextualSearch from 'src/components/contextual-search/ContextualSearch';
import ListGroupsContent from './ListGroupsContent';
import { isIOS, isFirefox } from 'src/core/util/browser';
import scrollHack  from 'src/core/util/scrollHack';
import { getAdKeyForPage } from 'src/core/config-json/ConfigJsonManager';

import * as actions from 'src/store/actions';


import './ListGroupsPage.scss';


const LOG_PREF = '[ListGroupsPage] ';


class ListGroupsPage extends Component {

    pageKey = LIST_GROUPS_PAGE_KEY;

    getPageTitle() {
        if (this.props.labels.data[this.props.input.dataType]) {
            return this.props.labels.data[this.props.input.dataType].plural;
        }
        return '';
    }

    /**
     * Update string visible in browser tab/history/favorites
     */
    setDocumentContext() {
        if (this.props.isActive(this.pageKey)) {
            this.props.setDocumentContext(this.getPageTitle());
        }
    }

    componentDidMount(){
        this.fetchIfNeeded(this.props);
        this.props.actions.fetchFavorites();
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.fetchIfNeeded(nextProps);
    }
    componentDidUpdate(){
        this.setDocumentContext();
    }

    setContainerEl = el => {
        if (el) {
            this.eventsContainer = el;
            if (isIOS() || isFirefox()) {
                this.scroller = scrollHack(this.eventsContainer);
            }
        }
    }

    scrollTo = throttle((offset, indexKey) => {
        if (this.scroller) {
            this.scroller(offset);
        }
        else if (this.eventsContainer) {
            this.eventsContainer.scrollTop = offset;
        }
    }, 80)

    /**
     * Fetch data if :
     *  - data has been updated (shouldFetch: true)
     *  - or id is different
     *  - or if we have no groupedItems data and request is neither pending nor without result
     */
    fetchIfNeeded(props) {
        if (props.shouldFetch === true || this.props.input !== props.input ||
                (!props.groupedItems && props.isPending !== true && props.itemNotFound !== true)) {

            props.actions.fetchGroupedItems(props.input, this.props.input.dataType === DATA_TYPE_EVENTS ? [ 'eventType' ] : null);
        }
    }

    navigateToRootCategory = () => {
        let categoryDataType;
        if (CATEGORIES_DATA_TYPE.indexOf(this.props.input.dataType) !== -1) {
            categoryDataType = this.props.input.dataType;
        } else {
            categoryDataType = Object.keys(CATEGORIES_MAPPING).find(catDataType => (
                CATEGORIES_MAPPING[catDataType] === this.props.input.dataType
            ));
        }

        this.props.actions.navigate(LIST_PAGE_KEY, {
            inputs: [{ dataType: categoryDataType }]
        });
    }

    renderChildren = () => {
        const { searchResults } = this.props

        if (searchResults) {
            return (
                <ContextualSearch
                    items={searchResults}
                    favorites={this.props.favorites}
                    keyboardHeight={this.props.keyboardHeight}
                    pageKey={this.pageKey}
                    labels={this.props.labels}
                    actions={this.props.actions}
                />
            )
        }

        return (
            <ListGroupsContent
                // Fetch status props
                isPending={this.props.isPending}
                itemNotFound={this.props.itemNotFound}
                // Content props
                groupedItems={this.props.groupedItems}
                dataType={this.props.input.dataType}
                favorites={this.props.favorites}
                setContainerEl={this.setContainerEl}
                scrollTo={this.scrollTo}
                scrollToItem={this.props.scrollToItem}
                favIconDisabled={this.props.favIconDisabled}
                ad={this.props.ad}
                adKey={getAdKeyForPage(LIST_GROUPS_PAGE_KEY, [ this.props.input ])}
                sideIndexThreshold={this.props.sideIndexThreshold}
                // Common
                isPageVisible={this.props.isVisible}
                labels={this.props.labels}
                actions={this.props.actions}
                queryString={this.props.queryString} />
        )
    }

    render() {
        console.log(LOG_PREF+'render');

        // Avoid resetting contextual search field
        /*if (!this.props.isVisible && !this.props.searchResults) {
            return null;
        }*/

        return (
            <>
                <Menu
                    options={this.props.menuOptions}
                    actions={this.props.actions}
                    labels={this.props.labels}
                    profile={this.props.profile}
                    associatedPageKey={this.pageKey}
                    adConfig={this.props.adConfig}
                    twoColumns={this.props.twocolumns}
                    isLocationEnabled={this.props.isLocationEnabled}
                    swipeable={false}
                    />

                <AppToolbar
                    labels={this.props.labels}
                    isDisplayed={this.props.hasToolbar}
                    actions={this.props.actions}
                    title={this.getPageTitle()}
                    contextualTitle={this.props.contextualTitle}
                    contextualDataTypes={[this.props.input.dataType]}
                    searchFieldVisible={this.props.searchFieldVisible}
                    searchResults={this.props.searchResults}
                    pageKey={this.pageKey}
                    profile={this.props.profile}
                    hasBackButton={this.props.backButtonInToolbar}
                    hasHomeButton={this.props.homeButtonInToolbar}
                    hasFavButton={this.props.favButtonInToolbar}
                    hasSearchButton={this.props.searchButtonInToolbar}
                    hasMenuButton={this.props.menuButtonInToolbar}
                    hasSynopticAgendaButton={this.props.hasSynopticAgendaButton}
                    agendaProps={this.props.hasSynopticAgendaButton ? { input: this.props.input } : null}
                    rootCategoryButtonInToolbar={this.props.rootCategoryButtonInToolbar}
                    navigateToRootCategory={this.navigateToRootCategory}
                    theme={this.props.hasSynopticAgendaButton ? 'dark' : null}
                    />

                {this.renderChildren()}
            </>
        );
    }
};


ListGroupsPage.propTypes = {
    input       : PropTypes.object,
    groupedItems: PropTypes.object,
    favorites   : PropTypes.object,
    isPending   : PropTypes.bool,
    itemNotFound: PropTypes.bool,
    ad          : PropTypes.object,
    favIconDisabled: PropTypes.bool,
    sideIndexThreshold: PropTypes.number.isRequired,
    scrollToItem: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    // Common page props
    queryString       : PropTypes.string.isRequired,
    menuOptions       : PropTypes.object.isRequired,
    profile           : PropTypes.string,
    labels            : PropTypes.object.isRequired,
    actions           : PropTypes.object.isRequired,
    isActive          : PropTypes.func.isRequired,
    contextualTitle   : PropTypes.string,
    setDocumentContext: PropTypes.func.isRequired,
    adConfig          : PropTypes.object,
    isLocationEnabled : PropTypes.bool,
    searchFieldVisible: PropTypes.bool,
    searchResults     : PropTypes.object,
    keyboardHeight    : PropTypes.number,
    isVisible         : PropTypes.bool,
    // toolbar
    hasToolbar           : PropTypes.bool,
    homeButtonInToolbar  : PropTypes.bool,
    backButtonInToolbar  : PropTypes.bool,
    searchButtonInToolbar: PropTypes.bool,
    favButtonInToolbar   : PropTypes.bool,
    menuButtonInToolbar  : PropTypes.bool,
    rootCategoryButtonInToolbar: PropTypes.bool,
    hasSynopticAgendaButton: PropTypes.bool,
}

const mapStateToProps = (state, ownProps) => state[LIST_GROUPS_PAGE_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListGroupsPage);
