var defaultConf = require('./config-default');

var NODE_BACKEND_DOMAIN = '';
var NODE_WS_BAKEND_DOMAIN = '';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: 'pss-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_PATH: '/home/www/eventime/pss/pss2020/pss-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'kim1',
  DEPLOY_PATH: '~/eventime/pss/pss2020/pss-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-48506479-28',
  projectId: '',
  // FCM sender id
  appId: '',
  // pushwoosh appid
  REAL_TIME: {// URL: `https://${NODE_WS_BAKEND_DOMAIN}`
  },
  LOGIN: {// WS_URL: 'https://blockchain2020.site.calypso-event.net/testSDA/tgServices/BlockchainAppWs',
    // forgottenPasswordURL: 'https://blockchain2020.site.calypso-event.net/testSDA/authentification/mot-de-passe-oublie.htm',
  },
  USER_DATA: {// WS_URL: 'https://blockchain2020.site.calypso-event.net/testSDA/tgServices/BlockchainAppWs',
  },
  PARTICIPANTS: {// WS_URL: 'https://blockchain2020.site.calypso-event.net/testSDA/tgServices/BlockchainAppWs',
  },
  SEND_EXPORTED_NOTES_MAIL: {
    WS_URL: 'http://localhost:3096/api/exportNotesByMail',
    EXPEDITOR: 'no-reply@mobile-spot.com',
    KEY: 'zpgZkgiz845gjzFzi'
  }
});