
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { USER_DATA_PAGE_KEY } from 'src/pages/pagesKeys';
import { DATA_TYPE_EVENTS } from 'data/config/dataConfig';

import UserDataForm from './UserDataForm';
import Tabs from 'src/components/tabs/Tabs';
import List from 'src/components/list/List';


const LOG_PREF = '[UserDataPageContent] ';

class UserDataContent extends Component {

    state = {
        tabIndex: 0,
    }

    tabsContentRenderer(tabIndex) {
        switch (tabIndex) {

            case 0: return <UserDataForm {...this.props} />;

            case 1: return (<List items={ this.props.userData.events }
                       dataType={ DATA_TYPE_EVENTS }
                       actions={ this.props.actions }
                       labels={ this.props.labels }
                       displayFavorites={ false }
                       pageKey={ USER_DATA_PAGE_KEY } />
                    );

            default: return null;
        }
    }

    getEventsCount = () => Array.isArray(this.props.userData.events) ? this.props.userData.events.length : 0

    getTabsData = () => [
        { key: 0,
          isCurrentTab : () => this.state.tabIndex === 0,
          getTabContent: () => this.props.labels.userData.yourProfile,
        },
        { key: 1,
          isCurrentTab : () => this.state.tabIndex === 1,
          // isClickable  : () => this.getEventsCount() > 0,
          getTabContent: () => this.props.labels.userData.yourEvents,
        }
    ]

    onTabClick = index => {
        this.setState({ tabIndex: index });
    }

    render() {
        let hasEvents = this.getEventsCount() > 0;
        return (
            <div id="ud-content-container" className="content-below-apptoolbar">

                { hasEvents &&
                    <Tabs
                        currentIndex={this.state.tabIndex}
                        onTabIndexChange={this.onTabClick}
                        data={this.getTabsData()} />
                }

                { this.tabsContentRenderer(hasEvents ? this.state.tabIndex : 0) }
            </div>
        );
    }
}

UserDataContent.propTypes = {
    userData: PropTypes.object,
    status  : PropTypes.object,
    sections: PropTypes.array.isRequired,
    tosLink : PropTypes.string,
    logout  : PropTypes.func.isRequired,
    labels  : PropTypes.object.isRequired,
    actions : PropTypes.object.isRequired,
};

export default UserDataContent;