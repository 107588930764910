module.exports = {
  name: 'PSS 2020',
  client: 'PARIS SHOULDER SYMPOSIUM 2020',
  description: "Mobile app for PARIS SHOULDER SYMPOSIUM 2020",
  pluginId: 'mobigeo-plugin-pss',
  version: '1.0.0',
  validLangs: ['en'],
  native: {
    version: '1',
    author: 'Mobile-Spot',
    backgroundColor: '0xffffff',
    // iOS
    // appleTeamId: '2BAP3P29V2',
    // iosAppStoreUrl: 'https://itunes.apple.com/app/paris-air-show/id1484463502',
    iosBuildTarget: '11.0',
    // Android
    // 'androidapp-id': 'TODO', // unused?
    // playStoreUrl: 'https://play.google.com/store/apps/details?id=com.mobilespot.sofcot',
    androidFadeSplashScreenDuration: 750,
    androidSigning: {
      keyAlias: 'mobilespot',
      keyStorePassword: 'jee1Uu0Hieloh7bi'
    },
    // Web app to wrap in the cordova app ('ionic/www', 'simple', 'test', 'demo', 'app-react' - see cordova/testapps/)
    app: 'app-react'
  },
  // List the folders of the data bundles to include for this project
  data: {
    default: 'oR9voo2k'
  },
  // 'location', 'location.geogroup', 'location.geopush', 'map.route', 'assets.update', 'poi.aroundme', 'poi.states'
  features: [],
  location: {
    mapping: {},
    registering: {
      location: false,
      geofencing: false
    }
  },
  undeliveredFolders: ['source', 'exports'],
  cordova: true,
  crypto: false,
  customFileUrlScheme: 'msfile',
  cordova_plugins: {
    mobigeo_plugin_dependencies: [{
      id: 'cordova-plugin-file-transfer'
    }],
    app_dependencies: [{
      id: 'cordova-android-support-gradle-release'
    }, // Solve android support dependencies (versions conflict)
    {
      id: 'cordova-plugin-inappbrowser'
    }, {
      id: 'cordova-plugin-splashscreen'
    }, {
      id: 'cordova-plugin-x-socialsharing'
    }, // for warning message: 'config file undefined requested for changes not found ...'
    // see: https://github.com/Pushwoosh/pushwoosh-phonegap-plugin/issues/297#issuecomment-418101837
    // { id: 'pushwoosh-cordova-plugin' },
    {
      id: 'cordova-plugin-urloverride'
    }, {
      id: '../src-app-plugins/cordova-plugin-mobilespot-app',
      variable: 'HOST',
      varValue: 'web.url'
    }, {
      id: 'https://github.com/Mobile-Spot/pushwoosh-phonegap-plugin.git'
    }, {
      id: 'cordova-plugin-ionic-keyboard'
    }, {
      id: 'cordova-plugin-wkwebview-inject-cookie'
    }, {
      id: 'https://github.com/surgeventures/cordova-plugin-cookie-manager.git'
    }, {
      id: 'cordova-plugin-statusbar'
    }]
  },
  geoGroup: {},
  web: {}
};