import React, { PureComponent, Component } from "react";
import PropTypes from "prop-types";


import Button from "src/components/button";
import List from "src/components/list/List";
import TypeBar from "src/components/type-bar/TypeBar";
import NoResult from "src/components/no-result/NoResult";
import AllOnMapButton from "src/components/all-on-map-button/AllOnMapButton";
import { stripToIds } from "src/pages/map/mapUtil";

import { iosHackScrollTo0 } from "src/core/util/browser";

import { MAP_TYPE } from "data/config/mapConfig";

import { NOTES_PAGE_KEY } from "src/pages/pagesKeys";

import ShareButton from "src/components/share-button/ShareButton";

import Pages, { PAGE_CSSCLASS } from "src/pages/Pages";
import { DATA_TYPE_TO_PAGE_KEY } from "src/pages/dataToPageMapping";

import CTAButton from 'src/components/cta-button/CTAButton';







const DEFAULT_DIALOG_PROPS = {
    isCancelable: true,
    animation: "default", // or 'none', no other choice sadly
    className: "project-font"
};

class NotesContent extends Component {
    UNSAFE_componentWillUpdate(nextProps) {}

    render() {
        let typedLists = [];
        

        if (this.props.notes) {
            Object.keys(this.props.notes).forEach(dataType => {
                let items = this.props.data[dataType];

                // Ignore this dataType if it has no data
                if (
                    items &&
                    items.length
                ) {
                    const count = items.length,
                        listProps = {
                            items: items,
                            dataType: dataType,
                            notes: this.props.notes,
                            notesStr: JSON.stringify(this.props.notes),
                            isPending: this.props.isPending,
                            actions: this.props.actions,
                            labels: this.props.labels,
                            pageKey: this.pageKey,
                            displayFavorites: false,
                            isNotesList: true
                        };
                    typedLists.push(
                        <TypeBar
                            key={dataType}
                            label={
                                this.props.labels.data[dataType][
                                    count > 1 ? "plural" : "singular"
                                ]
                            }
                            count={count}
                        >
                            <List {...listProps} />
                        </TypeBar>
                    );
                }
            });
        }

        return (
            <div className="favorites-content content-font content-below-apptoolbar">

                {typedLists.length === 0 ? (
                    <NoResult
                        labels={this.props.labels}
                        customLabel={this.props.labels.favorites.noResults}
                    />
                ) : (
                    <div>
                        <div className="notes-options-container">
                            <div className="notes-button-container">

                                <CTAButton
                                    action={() => { this.props.actions.exportNotes(this.props.notes, this.props.data); }}
                                    label={this.props.labels.notes.exportButton} />

                            </div>
                        </div>
                        <div>{typedLists}</div>
                    </div>
                )}
            </div>
        );
    }
}

NotesContent.propTypes = {
    data: PropTypes.object,
    notes: PropTypes.object,
    isPending: PropTypes.bool,
    isSynchroFavFeatureEnabled: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    // Common
    labels: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    profile: PropTypes.string,
};

export default NotesContent;
