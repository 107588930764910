
export const WS_ERRORS = {
    AUTH   : 'AUTH_ERROR',
    NETWORK: 'NETWORK_ERROR',
    WRONG_PARAMETER: 'WRONG_PARAMETER_ERROR',
    UNKNOWN: 'UNKNOWN_ERROR',
};

export function getErrorFromHttpStatus(httpStatus) {
    switch (httpStatus) {
        // case 400: return WS_ERRORS.WRONG_PARAMETER;
        case 401: return WS_ERRORS.AUTH;

        case 500: // fall through
        default:
            return WS_ERRORS.UNKNOWN;
    }
}

