
import React         from 'react';
import ReactDOM      from 'react-dom';
import { Provider }  from 'react-redux';

import config from 'data/config/config';
import { TYPE as TRANSITION_TYPE } from 'data/config/transitionConfig';
import { getArea } from 'data/config/pagesTwoColumnsConfig';

import { getDatatypeFromPage } from 'src/pages/dataToPageMapping';

import * as AppStatus from 'src/core/AppStatus';
import { get as getLabels } from 'src/core/Lang';
import { get as getCurrentProfile } from 'src/core/Profile';
import Pages, { PAGE_CSSCLASS } from 'src/pages/Pages';
import { LOGIN_PAGE_KEY } from 'src/pages/pagesKeys';
// import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';
import { getKeysOfPagesUsingGenericItem } from 'src/pages/generic-item-page/helpers';
import { perform as performTransition } from 'src/core/navigation/transition/PageTransition';
import { getCurrentPageKey, setCurrentPageKey } from 'src/core/navigation/CurrentRoute';
import TwoColumnsModeManager from 'src/core/navigation/TwoColumnsModeManager';

import {
    push as pushHistory,
    replaceState as replaceHistory,
    getNavigationIndex,
    getLocation
} from 'src/core/navigation/History';

import { getIdFromOriginalId } from 'src/core/query/Query';

import { HISTORY_ACTIONS } from './History';

import {
    hasNavigated,
    navigate,
    updatePageState,
    // showNotification,
} from 'src/store/actions';


const LOG_PREF = '[Router] ';

const ENCODE_URI_OPTIONS = true;


let rootContainer,
    reduxStore;

export function setRootContainer(el) {
    rootContainer = el
};

export function setReduxStore(store) {
    reduxStore = store;
};


/**
 * @param  {string} pageTitle
 */
function setDocumentContext(pageTitle) {
    const documentTitle = getLabels().common.appTitle + config.APP_TITLE_SEPARATOR + pageTitle;
    document.title = documentTitle;
}


export const isActive  = pageKey => Pages[pageKey].active === true

export function isMounted(pageKey) {
    let childrenLength = rootContainer.children.length;
    let found = false;
    for (let i=0; i<childrenLength && !found; i++) {
        found = rootContainer.children.item(i).id === Pages[pageKey].elId;
    }
    return found;
}


export function isPageVisible(pageKey) {
    if (getCurrentPageKey() === pageKey) {
        return true;
    }
    if (TwoColumnsModeManager.isEnabled()){
        return isActive(pageKey);

    }
    return false;
}

/**
 * Ability to queue navigation (e.g until data is ready, or profile has been selected...)
 * @see bootMiddleware
 */
export const queuedNavigation = (function(){
    let data;

    function parse() {
        let typeOfData = typeof data;
        if (typeOfData === 'object' && data !== null) {
            return data;
        } else if (typeOfData === 'function') {
            return data();
        } else {
            console.error(LOG_PREF+'Unexpected queued data: '+typeOfData);
        }
    }

    function set(_data) {
        data = _data;

        if (AppStatus.hasBooted()) {
            // no need to queue
            let _routeData = parse();
            reduxStore.dispatch(navigate(_routeData.pageKey, _routeData.props));
            clear();
        }
    }

    function get() {
        return parse();
    }

    function isEmpty() {
        return !data;
    }

    function clear() {
        data = null;
    }

    return {
        set,
        get,
        isEmpty,
        clear,
    };
})();


/**
 * Mount a react component in a dedicated DOM container
 * @param {string} pageKey (@see Pages object)
 * @param {object} pageProps (component props)
 */
function displayPage(pageKey, pageProps) {

    // Check arguments
    let hasError = false;
    if (!pageKey) {
        console.error(LOG_PREF+'Missing argument `pageKey`');
        hasError = true;
    }
    else if (!Pages[pageKey]) {
        console.error(LOG_PREF+'Invalid argument `pageKey`', pageKey);
        hasError = true;
    }
    if (hasError) {
        return;
    }
    let page = Pages[pageKey],
        pageEl = page.getElement();

    // Transmit new props
    let newProps = Object.assign({}, pageProps, {
        queryString: window.history.state ? window.history.state.queryString : '',
        backButtonInToolbar: getNavigationIndex() > 0,
        hasToolbar: TwoColumnsModeManager.pageHasToolbar(pageKey),
        isActive: isActive,
        setDocumentContext: setDocumentContext,
        pageKey: pageKey,
    });
    reduxStore.dispatch(updatePageState(pageKey, newProps));

    // Mount page if not already mounted
    if (!isMounted(pageKey)) {
        console.debug(LOG_PREF+'Mounting page '+(page.component.displayName || 'at '+page.elId));

        if (!pageEl) {
            // Create DOM container
            pageEl = document.createElement('div');
            pageEl.id = page.elId;
            pageEl.classList.add(PAGE_CSSCLASS);

            // Two columns mode
            if (TwoColumnsModeManager.isEnabled()) {
                pageEl.classList.add(getArea(pageKey));
            }

            rootContainer.appendChild(pageEl);

            page.setElement(pageEl);
        }

        // Mount react component
        ReactDOM.render(
            <Provider store={reduxStore}>
                <page.component />
            </Provider>,
            pageEl,
        );
    }

    if (getKeysOfPagesUsingGenericItem().indexOf(pageKey) !== -1) {
        // Update dom container class list for GenericItemPage component
        getKeysOfPagesUsingGenericItem().forEach(pageKey => {
            let className = Pages[pageKey].className;
            if (className && pageEl.classList.contains(className)) {
                pageEl.classList.remove(className);
            }
        });
        if (page.className) {
            pageEl.classList.add(page.className);
        }
    }
}


function getPreviousPage(pageKeyToDisplay) {
    if (TwoColumnsModeManager.isEnabled()) {
        // Find the page currently displayed in the area where the new page is displayed (right or left)
        let area = getArea(pageKeyToDisplay);

        let matches = Object.keys(Pages).filter(_pageKey => {
            return _pageKey !== pageKeyToDisplay
                && Pages[_pageKey].active
                && Pages[_pageKey].getElement()
                && Pages[_pageKey].getElement().classList.contains(area);
        });

        if (matches && matches.length > 0) {
            if (matches.length > 1) {
                console.warn(LOG_PREF+'Only a single page should be displayed per area, found: ', matches);
            }
            console.info('Will hide '+matches[0]);
            return matches[0];
        }
    }
    else {
        return getCurrentPageKey();
    }
};



/**
 * Navigate to a page
 * NB:
 *  - Exposed for routerMiddleware only!
 *  - Navigation is performed with action NAVIGATE
 *
 * @param {String} pageKey (@see pages/pagesKeys)
 * @param {object} pageProps
 * @param {TRANSITION_TYPE} transition
 * @param {string} historyAction
 */
export function _navigate(pageKey, pageProps, transition=TRANSITION_TYPE.forward, historyAction) {
    let _page;

    // Check page argument for empty value
    if (!pageKey) {
        console.error(LOG_PREF+'Missing argument `pageKey`');
        return;
    }
    // check key
    else if (typeof pageKey === 'string') {
        _page = Pages[pageKey];
        if (typeof _page === 'undefined') {
            console.error(LOG_PREF+'Invalid argument `pageKey`', pageKey);
            return;
        }
    }

    // Check if access to the page is granted
    if (typeof _page.isGranted === 'function' && _page.isGranted(pageProps) !== true) {
        console.warn(`Access to page ${pageKey} is denied`);
        /*reduxStore.dispatch(showNotification({
            message: getLabels().common.navigationDenied,
            level  : NotificationLevels.WARNING,
        }));*/
        reduxStore.dispatch(navigate(LOGIN_PAGE_KEY, { nextRoute: { pageKey, pageProps, }}));
        return;
    }
    if (typeof _page.onShow === 'function') {
        _page.onShow();
    }

    if (!pageProps) {
        pageProps = {};
    }

    // Add two columns property to every page component
    if (TwoColumnsModeManager.isEnabled()) {
        pageProps.twocolumns = true;
    }

    console.log(LOG_PREF+'navigate '+_page.key+', pageProps:', pageProps);

    let pageHasChanged = _page.key !== getCurrentPageKey();

    let previousPageKey = getPreviousPage(pageKey);
    if (previousPageKey) {
        Pages[previousPageKey].active = false;
    }

    _page.active = true;
    setCurrentPageKey(_page.key);


    // Update navigation status (url/history/title)
    // Do it before displaying the page
    // It allows the page to know its url on render (useful for share buttons)
    switch(historyAction) {
        case HISTORY_ACTIONS.PUSH:
            pushHistory(_page, pageProps);
            break;

        case HISTORY_ACTIONS.REPLACE:
            replaceHistory(_page, pageProps);
            break;

        case HISTORY_ACTIONS.NONE:
            break;

        default:
    }

    // Mount or display demanded page
    displayPage(_page.key, pageProps);

    // Set page container visible !
    if (pageHasChanged) {
        performTransition(
            getCurrentPageKey(),
            previousPageKey,
            null, // No callback
            TwoColumnsModeManager.isEnabled() ? null : transition
        );
    }


    // Display a HAS_NAVIGATED action (e.g for google analytics)
    reduxStore.dispatch( hasNavigated(pageKey, pageProps, previousPageKey) );
};


/**
 * Return current query string (which contains page key and page props)
 * @return {string}
 */
export function getCurrentQueryString() {
    const qs = getLocation().href.slice( getLocation().href.indexOf(config.ROUTE_SEPARATOR) );

    return ENCODE_URI_OPTIONS ? decodeURI(qs) : qs;
};



export function parseCurrentUrl(skipDefault) {
    return parseUrl(getLocation().href, skipDefault);
};

export function parseUrl(url, skipDefault) {
    let separatorIndex = url.indexOf(config.ROUTE_SEPARATOR),
        props = {},
        pageKey;

    if (separatorIndex !== -1) {

        // Parse querystring to an object
        url.substring(separatorIndex+1).split('&').forEach(keyValuePair => {
            let [ key, value ] = keyValuePair.split('='),
                decodedValue = null;

            if (typeof value !== 'undefined') {
                if (ENCODE_URI_OPTIONS) {
                    decodedValue = decodeURI(value);
                } else {
                    decodedValue = value;
                }

                if (['[','{'].indexOf(decodedValue.slice(0,1)) !== -1) {
                    decodedValue = JSON.parse(decodedValue);
                }
            }
            props[key] = decodedValue;
        });
        Object.keys(props).forEach(key => {
            // `originalId` should remain a string
            if (key !== 'originalId') {
                // parse integers
                if (/^\d+$/.test(props[key]) === true) {
                    props[key] = parseInt(props[key], 10);
                }
                // parse booleans
                else if (props[key] === 'true') {
                    props[key] = true;
                } else if (props[key] === 'false') {
                    props[key] = false;
                }
            }
        });

        // Determine pageKey from path (= query parameter name starting with a '/')
        Object.keys(props).forEach(key => {
            if (key.slice(0,1) === '/'){

                let matchingPageKeys = Object.keys(Pages).filter((pageKey) => Pages[pageKey].path === key);
                if (matchingPageKeys.length === 0) {
                    console.error(LOG_PREF+'Cannot determine page matching path: '+key);
                    return;
                }
                pageKey = matchingPageKeys[0];

                // Avoid transmitting an empty page props object
                if (Object.keys(props).length === 1) {
                    props = null;
                } else {
                    delete props[key];
                }
            }
        });
    }

    if (typeof pageKey === 'undefined' && skipDefault !== true) {
        if (getCurrentProfile()) {
            let defaultPage = config.getHomePage(getCurrentProfile());
            if (defaultPage) {
                pageKey = defaultPage.pageKey;
                props = defaultPage.props || null;
            }
        }
        return null;
    }

    props = parseSpecialParameters(props, pageKey);

    return {
        pageKey,
        props,
    };
}
if (config.ENV === 'dev') {
    global.parseUrl = parseUrl;
}



/**
 * Handle certains parameters in a specific way.
 * e.g originalId -> determine id
 * @param  {object} props
 * @param  {string} pageKey
 * @return {object} updated props
 */
export function parseSpecialParameters(props, pageKey) {
    if (props) {
        if (typeof props.originalId !== 'undefined') {
            props.id = getIdFromOriginalId(props.originalId, getDatatypeFromPage(pageKey));
            delete props.originalId;
        }
    }
    return props;
}


/**
 * Called on initial page load, parse querystring to determine the page to display
 * @param {string} historyAction
 */
export function applyCurrentUrl(historyAction) {
    let parsed = parseCurrentUrl();

    reduxStore.dispatch(
        navigate(parsed.pageKey, parsed.props, historyAction)
    );
};
