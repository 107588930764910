
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ImageUpload from 'src/components/form/ImageUpload';
import InputModal from 'src/components/form/InputModal';
import InputSwitch from 'src/components/form/InputSwitch';
import Url from 'src/components/url/Url';
import { WS_ERRORS } from 'src/core/webservices/WS_ERRORS';
import NotificationLevels from 'src/components-standalone/notifications/NotificationLevels';
import { getValue } from 'src/core/util/JsTools';

import './UserDataForm.scss';

const LOG_PREF = '[UserDataPageContent] ';


export const SAVING_NOTIF_ID = 'user-data-saving-notif';


class UserDataForm extends Component {

    getFieldConfiguration(fieldName) {
        let fieldConfig;
        for (let i=0; i<this.props.sections.length && !fieldConfig; i++) {

            let fieldsName = Object.keys(this.props.sections[i].fields);
            if (fieldsName.indexOf(fieldName) !== -1) {
                fieldConfig = this.props.sections[i].fields[fieldName];
            }
        }
        return fieldConfig;
    }

    parseValue = (fieldName, value) => {
        let fieldConfig = this.getFieldConfiguration(fieldName);

        switch(fieldConfig.type) {

            case 'bool':
                return !!value;

            case 'number':
                return parseInt(value, 10);

            default:
                return value;
        }
    }

    remoteSave = (fieldName, value) => {
        let newValue = this.parseValue(fieldName, value);

        // proceed to update
        this.props.actions.remoteSaveUserData({
            [fieldName]: newValue,
        });
    }

    /**
     * Check error for a single field
     * @return {boolean}
     */
    hasError = (name, value) => {
        let fieldConfig = this.getFieldConfiguration(name);
        if (!fieldConfig) {
            console.error(LOG_PREF+`Handling undeclared field '${name}' (should not happen)`);
            return false;
        }

        // value is a string or undefined
        if ((!value || value.length === 0) && fieldConfig.isRequired === true) {
            return true;
        }
        if (fieldConfig.pattern && fieldConfig.pattern.test(value) !== true) {
            return true;
        }
        return false;
    }

    /*areEquals(val1, val2) {
        if (typeof val1 === 'object') {
            return JSON.stringify(val1) === JSON.stringify(val2);
        }
        return val1 === val2;
    }*/

    componentDidUpdate(prevProps, prevState) {
        if (prevProps && prevProps.status
            && JSON.stringify(prevProps.status) !== JSON.stringify(this.props.status)) {

            let { saving, error } = this.props.status;

            // status has change

            if (!prevProps.status.saving && saving) {
                // 1-request ongoing
                this.props.actions.showNotification({
                    uid: SAVING_NOTIF_ID,
                    message: this.props.labels.common.saving,
                    duration: 15,
                });
            }
            else if (prevProps.status.saving && !saving) {
                // 2-request end
                this.props.actions.removeNotification(SAVING_NOTIF_ID);
                // Use incoming this.props.userdata / purge local state
                this.resetLocalStates();

                if (!error) {
                    // success
                    this.props.actions.showNotification({
                        message: this.props.labels.common.saved,
                        duration: 2,
                        level: NotificationLevels.SUCCESS,
                    });
                } else {
                    // failure
                    let message;
                    switch (error) {
                        case WS_ERRORS.AUTH:
                            message = this.props.labels.userData.invalidAuth;
                            break;
                        case WS_ERRORS.NETWORK:
                            message = this.props.labels.userData.noNetwork;
                            break;
                        default:
                            message = this.props.labels.userData.updateFailed;
                    }
                    this.props.actions.showNotification({
                        message: message,
                        level: NotificationLevels.ERROR,
                    });
                }
            }
        }
    }

    setSwitchRef = ref => {
        if (!this._refs) {
            this._refs = {};
        }
        if (ref) {
            this._refs[ref.getFieldName()] = ref;
        }
    }

    resetLocalStates() {
        if (this._refs) {
            Object.keys(this._refs).forEach(fieldName => {
                let ref = this._refs[fieldName];
                if (ref) {
                    ref.resetState();
                }
            });
        }
    }

    handleLogoutClicked = () => {
        this.props.logout();
        this.props.actions.navigateToHome();
    }

    renderSection = (section, index) => (
        <section key={index} className={section.className}>
            { section.label &&
                <label>{ getValue(this.props.labels, section.label) }</label>
            }
            <div className="ud-section-fields">
                { Object.keys(section.fields).map(this.renderRow) }
            </div>
        </section>
    )

    getFieldLabel = fieldName => this.props.labels.userData.fields[fieldName]

    renderRow = fieldName => {
        let field = this.getFieldConfiguration(fieldName),
            value = this.props.userData[fieldName];

        if (field.condition && !this.props.userData[field.condition]) {
            return null;
        }

        switch(field.type) {

            case 'bool':
                return <InputSwitch
                            ref={this.setSwitchRef}
                            key={fieldName}
                            name={fieldName}
                            label={this.getFieldLabel(fieldName)}
                            value={!!value}
                            editable={field.editable}
                            submit={this.remoteSave}
                            />

            case 'image':
                return <ImageUpload
                            key={fieldName}
                            image={value}
                            onSelect={image => { this.remoteSave(fieldName, image) }}
                            requestStatus={this.props.status}
                            readOnly={!field.editable}
                            labels={this.props.labels}
                            actions={this.props.actions}
                            />;

            default:
                return <InputModal
                            key={fieldName}
                            name={fieldName}
                            type={field.type}
                            value={value}
                            readOnly={!field.editable}
                            required={field.required}
                            disabled={false} // !field.editable
                            hasError={this.hasError}
                            placeHolder={this.getFieldLabel(fieldName)}
                            submit={this.remoteSave}
                            requestStatus={this.props.status}
                            labels={this.props.labels}
                            />
        }
    }

    renderButtons = () => ([
        <div key={0} className="generic-btn" onClick={this.handleLogoutClicked}>{this.props.labels.login.logout}</div>
    ])

    render() {
        let extRes = this.props.userData.externalResources;

        return (
            <div id="ud-container" className="content-font">

                <form id="ud-form">
                    { this.props.sections.map(this.renderSection) }
                </form>

                { extRes && extRes.url &&
                    <div className="ud-ext-ressources">
                        <Url href={extRes.url}
                             label={this.props.labels.userData.externalResources}
                             target={this.props.externalResourceLinkTarget}
                             />
                    </div>
                }

                { this.props.tosLink &&
                    <div className="ud-link-container">
                        <Url href={this.props.tosLink}
                             label={this.props.labels.login.tos}
                             target={this.props.tosLinkTarget}
                             />
                    </div>
                }

                <div className="generic-btn-container">{ this.renderButtons() }</div>
            </div>
        );
    }

};

UserDataForm.propTypes = {
    userData: PropTypes.object,
    status  : PropTypes.object,
    sections: PropTypes.array.isRequired,
    tosLink : PropTypes.string,
    logout  : PropTypes.func.isRequired,
    labels  : PropTypes.object.isRequired,
    actions : PropTypes.object.isRequired,
};

export default UserDataForm;