
// Libs
import React from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash-custom';

// conf
import { getSideIndexPosition } from 'data/config/listConfig';
import { VALID_DATA_TYPES } from 'data/config/dataConfig';

// App modules
import NoResult from 'src/components/no-result/NoResult';
import List from 'src/components/list/List';
import SideIndex from 'src/components/side-index/SideIndex';
import Loader from 'src/components/loader/Loader';
import { isIOS, isFirefox } from 'src/core/util/browser';
import scrollHack from 'src/core/util/scrollHack';
import Pages from 'src/pages/Pages';

import './AlphabeticalList.scss';


const LOG_PREF = '[AlphabeticalList] ';



class AlphabeticalList extends React.Component {

    state = {}

    setListContainerEl = el => {
        if (el) {
            this.listContainerEl = el;
            if (isIOS() || isFirefox()) {
                this.scroller = scrollHack(this.listContainerEl);
            }
        }
    }

    setSideIndexRef = ref => {
        this.sideIndex = ref;
    }

    scrollTo = throttle((offset, indexKey) => {
        if (this.props.contentByIndex) {
            if (!this.state.currentIndexKey || this.state.currentIndexKey !== indexKey) {
                // Reload content
                this.setState({ currentIndexKey: indexKey });
                this._scrollTo(0);
            }
        } else {
            this._scrollTo(offset);
        }
    }, 80)

    _scrollTo = offset => {
        let _offset = offset - this.listContainerEl.offsetTop; // AR-50

        if (this.scroller) {
            this.scroller(_offset);
        }
        else if (this.listContainerEl) {
            this.listContainerEl.scrollTop = _offset;
        }
    }

    componentDidUpdate(prevProps) {
        let dataTypeIsDifferent = this.props.dataType !== prevProps.dataType,
            itemsTypeHasChanged = typeof this.props.items !== typeof prevProps.items;

        // If datatype has changed then scroll to the top of the list
        if (dataTypeIsDifferent) {
            window.setTimeout(this.scrollTo, 100 , 0);
        }
        // If items have changed, refresh the side index
        else if (itemsTypeHasChanged
                || this.props.itemsCount !== prevProps.itemsCount) {

            this.sideIndex.resetScrollTopValues();

            if (!this.props.contentByIndex) {
                this.sideIndex.setSelectedIndexFromScrollPosition();
            } else {
                this.sideIndex.setSelectedStyle(
                    this.getCurrentIndexForContentByIndex());
            }
        }
    }

    getListContainerClassNames() {
        let classNames = ['alphalist-container'];

        // Determine side index position (left or right, default: right)
        let sideIndexPosition = getSideIndexPosition();
        if (sideIndexPosition === 'left') {
            classNames.push('alphalist-container-right');
        }

        return classNames.join(' ');
    }

    getList = (allItems, char) => (
        <List
            key={char}
            items={allItems[char]}
            dataType={this.props.dataType}
            favorites={this.props.favorites}
            actions={this.props.actions}
            labels={this.props.labels}
            isPending={this.props.isPending}
            isFastAndUgly={this.props.isFastAndUgly}
            displayFavorites={this.props.displayFavorites}
            pageKey={this.props.associatedPageKey}
            userData={this.props.userData}
            />
    );

    onScroll = event => {
        if (Pages[this.props.associatedPageKey].active && this.props.contentByIndex !== true) {
            this.sideIndex.setSelectedIndexFromScrollPosition(event.target.scrollTop);
        }
    }

    getCurrentIndexForContentByIndex = () => this.state.currentIndexKey || this.props.indexes[0]

    render() {
        console.log(LOG_PREF+'render');

        if (!this.props.dataType) {
            console.error(LOG_PREF+'Missing `dataType` property');
            return;
        }
        // Pending request
        if (this.props.isPending === true) {
            return <ul className="list-component"><Loader labels={this.props.labels} /></ul>;
        }
        // Nothing found
        if (!this.props.items && this.props.isPending !== true) {
            return <NoResult labels={this.props.labels} />;
        }

        return (
            <div className="alphalist-component"
                 style={typeof this.props.parentHeight === 'number' ? { height: this.props.parentHeight } : null}
                 >
                <div ref={this.setListContainerEl}
                     className={this.getListContainerClassNames()}
                     onScroll={this.props.contentByIndex !== true ? this.onScroll : null}>

                    { this.props.contentByIndex

                        ? this.getList(this.props.items, this.getCurrentIndexForContentByIndex())

                        : this.props.indexes.map(char => (
                            this.getList(this.props.items, char)
                        ))
                    }
                </div>

                <SideIndex
                    ref={this.setSideIndexRef}
                    indexes={this.props.indexes}
                    separatorsGetter={this.props.contentByIndex ? null : this.props.getSeparators}
                    contentByIndex={this.props.contentByIndex}
                    scrollTo={this.scrollTo} />

            </div>
        );
    }
};

AlphabeticalList.propTypes = {
    items            : PropTypes.object,
    indexes          : PropTypes.array,
    dataType         : PropTypes.oneOf(VALID_DATA_TYPES).isRequired,
    getSeparators    : PropTypes.func,
    favorites        : PropTypes.object,
    isPending        : PropTypes.bool,
    displayFavorites : PropTypes.bool,
    contentByIndex   : PropTypes.bool,
    isFastAndUgly    : PropTypes.bool,
    parentHeight     : PropTypes.number,
    itemsCount       : PropTypes.number,
    associatedPageKey: PropTypes.string.isRequired,

    // i18n labels (set for current language)
    labels: PropTypes.object.isRequired,

    // if specified, any occurence of this string will be emphasised
    highlight: PropTypes.string,

    clickOnTypeBar: PropTypes.func,
};

export default AlphabeticalList;
