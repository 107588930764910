
import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';

import { iosHackScrollTo0 } from 'src/core/util/browser';

import './InputModal.scss';

class InputModal extends React.PureComponent {

    state = {
        showModal: false,
    }

    showModal = evt => {
        evt.preventDefault();
        evt.stopPropagation();
        this.setState({ showModal: true });
    }

    hideModal = evt => {
        this.setState({ showModal: false });
    }

    updateValue = evt => {
        this.setState({ value: evt.target.value });
    }

    submit = evt => {
        this.props.submit(this.props.name, this.state.value);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps && prevProps.requestStatus.saving
                && !this.props.requestStatus.saving && !this.props.requestStatus.error) {
            this.hideModal();
        }
    }

    isValueModified = () => this.state.value !== undefined && this.state.value !== this.props.value

    renderInput(isDummyInput) {
        let {
            name,
            type,
            value,
            readOnly,
            required,
            disabled,
            hasError,
            placeHolder,
        } = this.props;

        let inputValue = isDummyInput ? value : (this.isValueModified() ? this.state.value : value);

        return (
            <input
                name={name}
                className={'generic-input' + (hasError(name, inputValue) ? ' generic-form-error' : '')}
                placeholder={isDummyInput ? placeHolder + (required ? ' *' : '') : null}
                type={type}
                value={inputValue}
                onBlur={iosHackScrollTo0}
                readOnly={isDummyInput ? true : !!readOnly}
                disabled={!!disabled}
                required={!!required}
                onClick={!readOnly && !disabled && isDummyInput ? this.showModal : null}
                onChange={!isDummyInput ? this.updateValue : null}
                />
        );
    }

    getModalButtons() {
        let buttons = [
            <div key={0}
                 className="generic-btn"
                 onClick={this.hideModal}>{this.props.labels.common.cancel}</div>
        ];
        if (this.isValueModified()) {
            buttons.push(
                <div key={1}
                     className={'generic-btn cta-modal-btn'+(this.props.requestStatus.saving ? ' modal-btn-disabled' : '')}
                     onClick={this.submit}
                     >{this.props.labels.common.save}</div>
            );
        }
        return buttons;
    }

    render() {
        return (
            <>
                { this.renderInput(true) }

                <Dialog className="input-modal"
                        open={this.state.showModal}
                        onBackdropClick={this.hideModal}
                        >
                    <DialogTitle className="title-font modal-title">{this.props.placeHolder}</DialogTitle>
                    <div className="input-modal-inner">

                        { this.renderInput(false) }

                        <div className="generic-btn-container">{ this.getModalButtons() }</div>
                    </div>
                </Dialog>
            </>
        );
    }
}

InputModal.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    placeHolder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    hasError: PropTypes.func.isRequired,
    submit: PropTypes.func.isRequired,
    requestStatus: PropTypes.object.isRequired,
};

export default InputModal;