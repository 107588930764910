
import getToolbarConfig from 'data/config/toolbarConfig';
import { DISABLE_FAVORITE_ICON } from 'data/config/listConfig';
import { DATA_TYPE_EVENTS } from 'data/config/dataConfig';

import STATUS from 'src/store/fetchStatuses';

import { getAdConfig }    from 'src/core/config-json/ConfigJsonManager';
import { getPollConfig }  from 'src/core/polls/PollManager';
import { getOrientation } from 'src/core/util/DomTools';
import { getPlaceholder } from 'src/core/search/searchUtil';
import { isPageVisible }  from 'src/core/navigation/Router';

import { get as getLabels, formatLongDateTime } from 'src/core/Lang';
import { get as getProfile } from 'src/core/Profile';

/**
 * Encapsulate the idea of passing a new object as the first parameter
 * to Object.assign to ensure we correctly copy data instead of mutating
 *
 * @param  {object} oldObject
 * @param  {object} newValues
 * @return {object}
 */
export const updateObject = (oldObject, newValues) => Object.assign({}, oldObject, newValues);


export function togglePageAfterNavigation(state, pageKey, navigatedToPageKey) {
    let isVisible = isPageVisible(pageKey);;

    if (state.isVisible === isVisible) {
        return state;
    } else {
        return Object.assign({}, state, { isVisible });
    }
}


export function getPageCommonDefaultState(pageKey) {
    const profile = getProfile();

    let state = {
        labels: getLabels(),
        profile: profile,
        menuOptions: { open: false },
        contributions: null,
        isLocationEnabled: false,
        isPMREnabled: false,
        favorites: {},
        favIconDisabled: DISABLE_FAVORITE_ICON === true,
    };

    if (pageKey) {
        state = Object.assign(state, getToolbarConfig(profile, pageKey));
    }
    return state;
};


export const setIsFavoriteFalse = state => updateObject(state, {
    isFavorite: false,
    favorites: {}
});

/**
 *
 * @param  {object} state
 * @param  {object} action
 * @param  {string} pageKey
 * @param  {boolean} shouldIncludeAdconfig
 * @return {object}
 */
export const profileChanged = (state, action, pageKey, shouldIncludeAdconfig) => {
    let newValues = { profile: action.profile };

    // Update toolbar config
    if (typeof pageKey === 'string') {
        newValues = Object.assign(newValues, getToolbarConfig(action.profile, pageKey));
    }

    // Include ad config
    if (shouldIncludeAdconfig) {
        newValues.adConfig = getAdConfig(action.profile);
    }

    return updateObject(state, newValues);
};


/**
 * Return true if one of `usedTables` is listed in `updatedTables`
 * @param  {array} updatedTables
 * @param  {array} usedTables
 * @return {boolean}
 */
export const hasATableBeenUpdated = (updatedTables, usedTables) => {
    if (!Array.isArray(updatedTables)) {
        return false;
    }

    if (!updatedTables || (updatedTables.length === 1 && updatedTables[0] === 'all')) {
        return true;
    }
    let match = false;

    for (let i=0; match === false && i<updatedTables.length; i++) {
        match = usedTables.indexOf(updatedTables[i]) !== -1;
    }
    return match;
};


/**
 *
 * @param  {object} state
 * @param  {object} action
 * @param  {array} usedTables
 * @return {object}
 */
export const dataUpdated = (state, action, usedTables) => {
    if (hasATableBeenUpdated(action.tables, usedTables) === true) {
        return updateObject(state, { shouldFetch: true });
    }
    return state;
};


function getPageTitle(item, dataType, labels) {
    let itemTitle;
    if (!item) {
        itemTitle = '';
    }
    else if (dataType === DATA_TYPE_EVENTS) {
        itemTitle = item.title;

        // Append date if any
        let startDate = item.start_date;
        if (startDate) {
            itemTitle += ' - '+formatLongDateTime(startDate, labels.id);

            // Append start time if any
            if (item.start_time) {
                itemTitle += ' '+item.start_time;

                // Append end time if any
                if (item.end_time) {
                   itemTitle += ' '+labels.events.to+' '+item.end_time;
               }
            }
        }
    }
    else {
        itemTitle = item ? item.title : '';
    }
    return labels.data[dataType].singular + (itemTitle ? labels.common.colon+itemTitle : '');
}


/**
 *
 * @param  {object} state
 * @param  {object} action
 * @param  {string} contextualDataType
 * @return {object}
 */
export const itemFetched = (state, action, contextualDataType) => {
    if (action.dataType === contextualDataType) {
        return updateObject(state, {
            id          : action.id,
            item        : action.item,
            isPending   : action.status === STATUS.PENDING,
            itemNotFound: action.status === STATUS.NO_RESULT,
            isFavorite  : action.isFavorite,
            hasNote     : action.hasNote,
            shouldFetch : false,
            pageTitle   : getPageTitle(action.item, action.dataType, state.labels),
        });
    }
    return state;
};


/**
 *
 * @param  {object} state
 * @param  {object} action
 * @return {object}
 */
export const langChanged = (state, action) => updateObject(state, { labels: action.labels });


/**
 *
 * @param  {object} state
 * @param  {object} action
 * @param  {string} contextualPageKey
 * @return {object}
 */
export const updatePageState = (state, action, contextualPageKey) => {
    if (action.pageKey === contextualPageKey) {
        return updateObject(state, action.props);
    }
    return state;
};


/**
 *
 * @param  {object} state
 * @param  {object} action
 * @param  {string} contextualPageKey
 * @return {object}
 */
export const toggleMenu = (state, action, contextualPageKey) => {
    if (action.pageKey === contextualPageKey) {
        return updateObject(state, { menuOptions: { open: action.isOpen }});
    }
    return state;
};


/**
 * Common TOGGLE_LOCATION_STATUS reducer function
 * @param  {object} state
 * @param  {object} action
 * @return {object}
 */
export const toggleLocationStatus = (state, action) => updateObject(state, { isLocationEnabled: action.value === true });


/**
 * Common TOGGLE_LOCATION_CAPABILITY_STATUS reducer function
 * @param  {object} state
 * @param  {object} action
 * @return {object}
 */
export const toggleLocationCapabilityStatus = (state, action) => updateObject(state, { isLocationReady: action.value === true });


/**
 * Common TOGGLE_PMR_STATUS reducer function
 * @param  {object} state
 * @param  {object} action
 * @return {object}
 */
export const togglePMRStatus = (state, action) => updateObject(state, { isPMREnabled: action.value === true });

/**
 * config.json file has been loaded and parsed
 * @param  {object} state
 * @return {object}
 */
export const configJsonLoaded = (state) => updateObject(state, { adConfig: getAdConfig(state.profile) });

/**
 * Poll config file has been loaded and parsed
 * @param  {object} state
 * @param  {object} action
 * @return {object}
 */
export const pollConfigLoaded = (state, action) => updateObject(state, { pollConfig: getPollConfig(state.profile) });


export const windowResized = (state, action) => updateObject(state, {
    lastResize : action.timestamp,
    orientation: getOrientation(),
});


export const setFavoritesSynchronizationStatus = (state, action) => updateObject(state, {
    synchroFavBtnStatus: action.status,
});


export const setSearchPlaceHolder = (state, action) => (
    updateObject(state, {
        placeholder: getPlaceholder(),
    })
);

export const setSearchPlacePlaceHolder = (state, action) => (
    updateObject(state, {
        searchPlacePlaceholder: getPlaceholder("searchPlace"),
    })
);

export const searchPerformed = (state, action, currentPageKey) => {
    if (action.pageKey !== currentPageKey) {
        return state;
    }

    const parseItems = (items) => {
        const parsedItems = {};
        // loop on data types
        Object.keys(items).forEach(dataType => {
            const _items = items[dataType]
            if (_items && Array.isArray(_items) && _items.length > 0) {
                parsedItems[dataType] = _items
            }
        });
        return parsedItems;
    }
    return updateObject(state, {
        searchResults: parseItems(action.results),
        contextualSearch: action.contextualSearch
    })
}

export function clearSearchResults(state, action, pageKey) {
    if (action.pageKey === pageKey) {
        return updateObject(state, {
            searchResults: null,
        })
    }
    return state;
}

export const fetchFavorites = (state, action) => (
    updateObject(state, {
        favorites: action.favorites
    })
)

export const toggleFavorite = (state, action, contextDatatypes) => {
    if (action.favListUpdated === true && contextDatatypes.indexOf(action.dataType) !== -1) {
        return updateObject(state, { favorites: action.favorites });
    }
    return state;
}

export const noteDeleted = (state, action) => {
    return updateObject(state, {
        note: action.note,
        notes: action.notes,
        notesData: action.notes.data
    });
};

export const noteSaved = (state, action) => {
    return updateObject(state, {
        note: action.note,
        notes: action.notes
    });
};

export const groupedItemsFetched = (state, action) => {
    return updateObject(state, {
        groupedItems: action.groupedItems,
        isPending   : action.status === STATUS.PENDING,
        itemNotFound: action.status === STATUS.NO_RESULT,
        favorites   : action.favorites,
        contextualTitle: action.contextualTitle,
        ad          : action.ad,
        hasSynopticAgendaButton: action.hasSynopticAgendaButton,
        shouldFetch : false,
    });
};

export const userDataUpdated = (state, action) => (
    updateObject(state, { userData: action.userData })
)

export const updateKeyboardState = (state, action) => (
    updateObject(state, {
        isKeyboardOpen: action.isOpen,
        keyboardHeight: action.height || 0,
    })
)

export function handleSetSearchFieldVisible(state, action, pageKey) {
    if (action.pageKey === pageKey) {
        return updateObject(state, { searchFieldVisible: action.isVisible });
    }
    return state;
}